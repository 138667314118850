import { Box, Button, Tooltip, Typography } from '@mui/material'
import ChevronRight from '@mui/icons-material/ChevronRight'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { rsvpFlow, waitlistFlow } from '../../../actions/event'
import {
  resetCurrentFormResponses,
  setCurrentTicketOption,
  setLocalOptions,
  updateLocalOption,
} from '../../../actions/rsvp'
import { getCurrentEvent } from '../../../selectors/event'
import { useIsLoggedIn } from '../../../utils/hooks'
import TicketsStepItem from '../../RsvpWizard/TicketsStepItem'
import { ExpandMore } from '@mui/icons-material'
import BashButton, {
  BashButtonType,
  ButtonViewAlignment,
} from '../../../shared-components/buttons/BashButton'
import BetaBadge from '../../EventCreation/BetaBadge'
import Logo from '../../../svg/logo/Icon'
import { RsvpStatus } from '../../../types/types'
import { idInfoIsEqual } from '../../../utils/eventFunctions'
import Row from '../../Row'
import Column from '../../Column'
import InvitedToJoinOptionView from './InvitedToJoinOptionView'

export function clamp(number, min, max) {
  return Math.max(min, Math.min(number, max))
}

interface Props {
  selectedRsvpStatus: RsvpStatus
  setSelectedRsvpStatus: (status: RsvpStatus) => void
  isOnWaitlist: boolean
  noPadding?: boolean
}

const EventTickets = ({
  selectedRsvpStatus,
  setSelectedRsvpStatus,
  isOnWaitlist,
  noPadding = false,
}: Props) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const event = useSelector(getCurrentEvent)
  const isLoggedIn = useIsLoggedIn()
  const loading = useSelector((state: any) => state.event.rsvpPending)
  const localOptions = useSelector((state: any) => state.rsvp.localOptions)

  const sortedJoinOptions = (event.joinOptions ?? [])
    .filter((j) => j.enabled === true || j.enabled === undefined)
    .filter((j) => j.errors == null || j.errors?.length === 0)
    .sort((a, b) => a.position - b.position)
    .sort((a, b) => b.hidden - a.hidden)
    .sort((a, b) =>
      idInfoIsEqual(a.idInfo, event.myGuest?.invitedToJoinOptionIdInfo)
        ? -1
        : 0,
    )

  const invitedJoinOption = sortedJoinOptions.find((j) =>
    idInfoIsEqual(j.idInfo, event.myGuest?.invitedToJoinOptionIdInfo),
  )

  const firstIndexThatIsAvailable = sortedJoinOptions.findIndex(
    (t) => t.available,
  )

  const availableTickets = sortedJoinOptions.filter((t) => t.available)

  const hasAvailableTickets = availableTickets.length > 0

  const hasEventix = sortedJoinOptions.some(
    (j) => j.idInfo?.source === 'EVENTIX',
  )
  const inEventWidget = useSelector((state: any) => state.rsvp.inEventWidget)

  const maxAmountOfTickets = Math.max(firstIndexThatIsAvailable + 1, 4)

  useEffect(() => {
    if (
      event.myGuest?.status === RsvpStatus.CANT ||
      event.myGuest?.status === 'NONE'
    ) {
      setSelectedRsvpStatus(RsvpStatus.JOINED)
    }
  }, [event.myGuest?.status])

  useEffect(() => {
    const updatedOptions = sortedJoinOptions.map((newOption) => {
      const existingOption = localOptions.find((prev) =>
        idInfoIsEqual(prev.idInfo, newOption.idInfo),
      )

      if (existingOption) {
        return {
          ...existingOption,
          ticketOption: newOption,
          code: newOption.code,
        }
      }

      // If no existing option, create new one
      const hasSelectedOption = localOptions.some((opt) => opt.selected)
      return {
        idInfo: newOption.idInfo,
        quantity: newOption.minAmountPerOrder ?? 1,
        ticketOption: newOption,
        code: newOption.code,
        selected:
          !hasSelectedOption &&
          idInfoIsEqual(newOption.idInfo, availableTickets[0]?.idInfo),
      }
    })

    // Add safety check - if no options are selected but we have available tickets,
    // select the first available ticket
    if (
      !updatedOptions.some((opt) => opt.selected) &&
      availableTickets.length > 0
    ) {
      const firstAvailable = updatedOptions.find((opt) =>
        idInfoIsEqual(opt.idInfo, availableTickets[0]?.idInfo),
      )
      if (firstAvailable) {
        firstAvailable.selected = true
      }
    }

    dispatch(setLocalOptions(updatedOptions))
  }, [event.joinOptions])

  const [showAll, setShowAll] = useState(event.code === 'hN3K0CRsuEOm') // Show all for figure8

  const totalTicketQuantity = localOptions.reduce(
    (total, ticket) => total + ticket.quantity,
    0,
  )

  const hasChooseYourOwnWithoutPrice = localOptions.find((e) => {
    return (
      (isNaN(e.price) || (e.price < 100 && e.price !== 0)) &&
      e.quantity > 0 &&
      e.ticketOption.chooseYourOwnPrice &&
      e.selected
    )
  })

  const joinDisabled =
    loading ||
    totalTicketQuantity === 0 ||
    !hasAvailableTickets ||
    hasChooseYourOwnWithoutPrice ||
    event.expired

  const onChangeItem = (idInfo, quantity, price) => {
    const ticketOption = event.joinOptions.find((t) =>
      idInfoIsEqual(t.idInfo, idInfo),
    )
    const existing = localOptions.find((t) => idInfoIsEqual(t.idInfo, idInfo))

    if (!existing?.selected) {
      return
    }

    const newQuantity =
      quantity != null
        ? Math.min(
            Math.max(1, quantity),
            ticketOption.quantityAvailableForYou ?? 99999,
          )
        : (existing?.quantity ?? 0)
    const newPrice = price != null ? Math.max(0, price) : existing?.price

    dispatch(
      updateLocalOption(idInfo, {
        quantity: newQuantity,
        price: newPrice,
        code: ticketOption.code,
      }),
    )
  }

  const onSelectItem = (idInfo) => {
    setSelectedRsvpStatus(RsvpStatus.JOINED)
    dispatch(resetCurrentFormResponses())

    const existing = localOptions.find((t) => idInfoIsEqual(t.idInfo, idInfo))

    // Somehow localoptions is not up to date with joinoptions?
    if (!existing) {
      dispatch(
        setLocalOptions(
          (event.joinOptions ?? []).map((e) => {
            return {
              idInfo: e.idInfo,
              quantity: 1,
              ticketOption: e,
              code: e.code,
              selected: idInfoIsEqual(e.idInfo, idInfo),
            }
          }),
        ),
      )
      return
    }

    if (!existing?.ticketOption.available || existing?.selected) {
      return
    }

    dispatch(
      setLocalOptions(
        localOptions.map((option) =>
          idInfoIsEqual(option.idInfo, idInfo)
            ? {
                ...option,
                selected: true,
              }
            : {
                ...option,
                selected: false,
              },
        ),
      ),
    )
  }

  const joinClicked = async () => {
    const selectedOption = localOptions.find((e) => e.selected)
    dispatch(setCurrentTicketOption(selectedOption))
    dispatch(rsvpFlow(selectedRsvpStatus, 'web_event_page'))
  }

  const waitListClicked = async () => {
    dispatch(waitlistFlow('web_event_page'))
  }

  const moreClicked = () => {
    setShowAll(true)
  }

  const noAvailableTickets = !availableTickets || availableTickets?.length === 0

  const oneFreePerGuest =
    availableTickets.length === 1 &&
    event.joinOptions?.length === 1 &&
    availableTickets[0]?.maxAmountPerGuest === 1 &&
    availableTickets[0]?.price === 0 &&
    !availableTickets[0]?.chooseYourOwnPrice &&
    availableTickets[0]?.hidden !== true &&
    !availableTickets[0].hasUrl &&
    !availableTickets[0].hasText

  const showChevron = () => {
    if (event.invitedBy) {
      return inEventWidget ? 'right' : 'down'
    }

    const selectedOption = localOptions.find((t) => t.selected)
    if (selectedOption) {
      const { ticketOption } = selectedOption

      if (ticketOption.questions && ticketOption.questions.length > 0) {
        return inEventWidget ? 'right' : 'down'
      }
      if (
        (ticketOption.price != null && ticketOption.price > 0) ||
        (ticketOption.chooseYourOwnPrice && (selectedOption.price ?? 0) > 0)
      ) {
        if (isLoggedIn) {
          return 'right'
        }
        return inEventWidget ? 'right' : 'down'
      }
    }
    if (isLoggedIn) {
      return 'none'
    }
    return inEventWidget ? 'right' : 'down'
  }

  const getWaitlistButtonText = () => {
    const allSoldOut = event.joinOptions.every(
      (e) =>
        !e.available &&
        (!e.availableFrom || dayjs(e.availableFrom).isBefore(dayjs())),
    )
    return allSoldOut ? t('joinWaitlist') : 'Pre-register'
  }

  const buttonText = () => {
    const selectedOption = localOptions.find((t) => t.selected)
    if (selectedOption && selectedRsvpStatus === RsvpStatus.JOINED) {
      const { ticketOption } = selectedOption
      if (ticketOption.approvalRequired) {
        return t('actionJoinRequest')
      }
      if (
        (ticketOption.price != null && ticketOption.price > 0) ||
        (ticketOption.chooseYourOwnPrice && (selectedOption.price ?? 0) > 0)
      ) {
        return selectedOption.quantity > 1 ? t('getTickets') : t('getTicket')
      }
    }
    if (event.invitedBy) {
      return t('respondRsvp')
    } else {
      return t('register')
    }
  }

  const feeString = () => {
    const selectedOption = localOptions.find((t) => t.selected)
    if (!selectedOption) {
      return null
    }
    const { ticketOption, quantity } = selectedOption

    const price = selectedOption.price ?? ticketOption.price ?? 0

    if (price === 0) {
      return null
    }

    if (
      ticketOption.orderFees?.fixed === 0 &&
      ticketOption.orderFees?.varyingPromille === 0
    ) {
      return null
    }

    const fixedTicketFee = ticketOption.fees?.applied?.fixed ?? 0
    const varyingTicketFee = ticketOption.fees?.applied?.varying ?? 0
    const totalTicketFee = fixedTicketFee + varyingTicketFee

    const formatter = new Intl.NumberFormat('en-EN', {
      style: 'currency',
      currency: ticketOption.currency ?? 'EUR',
    })

    const subtotal = (price + totalTicketFee) * quantity
    let subtotalFee = Math.round(
      (subtotal * ticketOption.orderFees.varyingPromilleExVat) / 1000,
    )
    subtotalFee = clamp(
      subtotalFee,
      ticketOption.orderFees.varyingMin ?? 0,
      ticketOption.orderFees.varyingMax ?? Number.MAX_SAFE_INTEGER,
    )
    const feeTax = Math.round((subtotalFee * ticketOption.orderFees.vat) / 1000)

    const feeInclTax = subtotalFee + feeTax + ticketOption.orderFees.fixed
    const total = subtotal + feeInclTax

    return {
      fee: t('totalFeeWithVat', {
        fee: formatter.format(feeInclTax / 100),
      }),
      total: formatter.format(total / 100),
    }
  }

  const getPoweredByText = () => {
    if (inEventWidget && hasEventix)
      return 'Powered by BASH • Tickets by Weeztix'
    if (!inEventWidget && hasEventix) return 'Powered by Weeztix'
    if (inEventWidget && !hasEventix) return 'Powered by BASH'
    return null
  }

  return (
    <Column
      sx={{
        gap: 2,
        p: noPadding ? 0 : 2,
      }}
    >
      {!oneFreePerGuest && !loading && invitedJoinOption != null && (
        <InvitedToJoinOptionView />
      )}

      {!oneFreePerGuest && !loading && sortedJoinOptions.length > 0 && (
        <Column
          sx={{
            gap: 1,
          }}
        >
          {sortedJoinOptions
            .slice(0, showAll ? 9999999 : maxAmountOfTickets)
            .filter(
              (j) =>
                showAll ||
                invitedJoinOption == null ||
                idInfoIsEqual(j.idInfo, invitedJoinOption.idInfo),
            )
            .map((ticket) => (
              <TicketsStepItem
                totalCount={event.joinOptions?.length}
                onSelect={() => {
                  onSelectItem(ticket.idInfo)
                }}
                selected={
                  localOptions.find((t) =>
                    idInfoIsEqual(t.idInfo, ticket.idInfo),
                  )?.selected
                }
                onPriceChange={(price) =>
                  onChangeItem(ticket.idInfo, null, price)
                }
                onChange={(quantity) =>
                  onChangeItem(ticket.idInfo, quantity, null)
                }
                key={JSON.stringify(ticket.idInfo)}
                ticket={ticket}
                quantitySelected={
                  localOptions.find((t) =>
                    idInfoIsEqual(t.idInfo, ticket.idInfo),
                  )?.quantity ?? 0
                }
                totalSelected={localOptions.reduce(
                  (sum, { quantity }) => sum + quantity,
                  0,
                )}
                disabled={selectedRsvpStatus !== RsvpStatus.JOINED}
              />
            ))}

          {(sortedJoinOptions.length > maxAmountOfTickets ||
            (invitedJoinOption != null && sortedJoinOptions.length > 1)) &&
            !showAll && (
              <Button onClick={moreClicked}>
                {invitedJoinOption == null && (
                  <Typography variant='button' color='primary'>
                    +{sortedJoinOptions.length - maxAmountOfTickets}{' '}
                    {t('more').toLowerCase()}
                  </Typography>
                )}
                {invitedJoinOption != null && sortedJoinOptions.length > 1 && (
                  <Row
                    sx={{
                      gap: 0.5,
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant='buttonSmall'
                      sx={{
                        color: 'grey.700',
                      }}
                    >
                      {t('showAll')}
                    </Typography>
                    <ChevronRight
                      sx={{
                        transform: 'rotate(90deg)',
                        width: 15,
                        height: 15,
                        color: 'grey.700',
                      }}
                    />
                  </Row>
                )}
              </Button>
            )}
        </Column>
      )}
      {feeString() != null && (
        <Typography variant='caption' sx={{ ml: 1, color: 'grey.800' }}>
          {feeString()!.fee}
          <span style={{ fontWeight: 500 }}>{feeString()!.total}</span>
        </Typography>
      )}
      <Box sx={{ width: 1, position: 'relative' }}>
        <Tooltip title={event.expired ? 'Event is over' : ''}>
          <div>
            {noAvailableTickets && event.code !== '4oMupNASVCs8' && (
              <BashButton
                type={
                  event.theme.colourScheme
                    ? BashButtonType.PRIMARY
                    : BashButtonType.SECONDARY
                }
                onClick={waitListClicked}
                sx={{ height: '40px', width: '100%' }}
                loading={loading}
                enabled={!event.expired && !isOnWaitlist}
                viewAlignment={ButtonViewAlignment.ALIGN_VIEW_END}
                extraView={<ChevronRight />}
              >
                {/*{ticketsAvailableLater ? t('preregister') : t('joinWaitlist')}*/}
                {isOnWaitlist ? buttonText() : getWaitlistButtonText()}
              </BashButton>
            )}
            {!noAvailableTickets && (
              <BashButton
                type={
                  event.theme.colourScheme || event.theme.darkMode
                    ? BashButtonType.PRIMARY
                    : BashButtonType.SECONDARY_DARK
                }
                extraView={
                  showChevron() === 'down' ? (
                    <ExpandMore />
                  ) : showChevron() === 'right' ? (
                    <ChevronRight />
                  ) : null
                }
                viewAlignment={ButtonViewAlignment.ALIGN_VIEW_END}
                loading={loading}
                enabled={!joinDisabled}
                onClick={joinClicked}
                sx={{ height: '40px', width: '100%' }}
              >
                {buttonText()}
              </BashButton>
            )}
          </div>
        </Tooltip>
      </Box>
      {(hasEventix || inEventWidget) && (
        <Row
          sx={{
            alignItems: 'center',
            gap: '6px',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          {!inEventWidget && hasEventix && (
            <Box
              component='img'
              src='/images/eventixLogo.svg'
              sx={{ width: 12, height: 10, color: 'primary.main' }}
            />
          )}
          {inEventWidget && (
            <Logo style={{ width: 9.5, height: 10, color: 'text.tertiary' }} />
          )}
          <Typography variant='caption' sx={{ fontSize: '0.625rem' }}>
            {getPoweredByText()}
          </Typography>
          {inEventWidget && hasEventix && (
            <Box
              component='img'
              src='/images/eventixLogo.svg'
              sx={{ width: 12, height: 10, color: 'primary.main' }}
            />
          )}
          {hasEventix && (
            <BetaBadge
              sx={{
                fontSize: '0.44rem',
                padding: '2px 4px',
                height: 'unset',
                marginLeft: 0,
                '& *': {
                  fontSize: '0.44rem',
                  lineHeight: '0.5rem',
                },
              }}
              className={undefined}
            />
          )}
        </Row>
      )}
    </Column>
  )
}

export default EventTickets
