import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Button, CircularProgress, SvgIcon, useTheme } from '@mui/material'
import FireThin from '../../svg/FireThin'
import { mdiFire } from '@mdi/js'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { rsvpHype } from '../../actions/rsvp'
import { useIsLoggedIn } from '../../utils/hooks'
import { EventDto } from '../../types/event'
import apiClient from '../../shared-components/utils/ApiClient'
import { openSignInModal } from '../../actions/modals'
import { Favorite, FavoriteBorder } from '@mui/icons-material'
import BashButton, {
  BashButtonType,
  ButtonViewAlignment,
} from 'shared-components/buttons/BashButton'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 4,
  },
  statusButton: {
    flexShrink: 0,
    minWidth: 0,
    minHeight: 0,
    borderRadius: 100,
    border: '1px solid ' + theme.palette.grey[200],
  },
  statusButtonHot: {
    border: 'none',
    background: 'transparent',
  },
  goingIcon: {
    color: theme.palette.secondary.main,
  },
  maybeIcon: {
    color: theme.palette.primary.main,
    marginLeft: -2,
  },
  cantIcon: {
    color: theme.palette.red.main,
  },
  icon: {
    '& path': {
      fill: 'url(#hypeGradient)',
    },
  },
  iconInterested: {
    color: theme.palette.text.primary,
    '& path': {
      fill: theme.palette.text.primary,
    },
  },
  progress: {
    position: 'absolute',
    left: 0,
    pointerEvents: 'none',
    '& .MuiCircularProgress-circle': {
      stroke: 'url(#hypeGradient)',
      strokeLinecap: 'round',
    },
  },
  hotCircle: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    borderRadius: 100,
    background:
      'linear-gradient(135deg, #F25F5C 0%, #F29D5C 50.52%, #F2D95C 100%)',
  },
  hypeContainer: {
    position: 'relative',
    background: theme.palette.background.paper,
    borderRadius: 100,
  },
  hypeContainerFilling: {
    // background: theme.palette.secondary[100],
  },
  isHotIcon: {
    color: '#FFFFFF',
    '& path': {
      fill: '#FFFFFF',
    },
  },
}))

interface HypeButtonProps {
  size?: 40 | 48
  event: EventDto
  setEvent?: ((EventDto) => void) | null
  renderStyle?: 'default' | 'interested'
}

const HypeButton = ({
  size = 48,
  event,
  setEvent,
  renderStyle = 'default',
}: HypeButtonProps) => {
  const { classes, cx } = useStyles()
  const dispatch = useDispatch()
  const activeGuest = event.myGuest
  const { t } = useTranslation('common')
  const isLoggedIn = useIsLoggedIn()
  const [loading, setLoading] = useState(false)
  const theme = useTheme()

  const isHot = event.hype?.hot

  const percentage = !event.hype
    ? 0
    : isHot
      ? 100
      : Math.min(
          (event.hype?.hypeScoreCurrent / event.hype?.threshold) * 100,
          100,
        )

  const hypeClicked = async (e) => {
    if (e.button !== 0) return
    e.preventDefault()
    e.stopPropagation()
    if (!isLoggedIn) {
      dispatch(openSignInModal(null, null, false, null, true, true))
      return
    }
    setLoading(true)
    if (activeGuest?.hype === true) {
      if (!setEvent) {
        await dispatch(rsvpHype('web_event_page', null, false))
      } else {
        const result = await apiClient.rsvp.hype(
          event.id,
          {
            hype: false,
          },
          event.code,
          location,
          null,
          null,
        )
        setEvent(result)
      }
    } else {
      if (!setEvent) {
        await dispatch(rsvpHype('web_event_page', null, true))
      } else {
        const result = await apiClient.rsvp.hype(
          event.id,
          {
            hype: true,
          },
          event.code,
          location,
          null,
          null,
        )
        setEvent(result)
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    setLoading(false)
  }, [activeGuest])

  // if ((activeGuest?.ticketCount ?? 0) > 0) return null

  // if (hasResponded) {
  //   return null
  // }

  if (event.privacyType !== 'PUBLIC') return null

  // Render the "Interested" style button
  if (renderStyle === 'interested') {
    return (
      <BashButton
        type={BashButtonType.WHITE_GREY_BORDER}
        onClick={(e) => hypeClicked(e)}
        enabled={!loading}
        height={32}
        viewAlignment={ButtonViewAlignment.ALIGN_TEXT_START}
        loading={loading}
        smallerIcon
        extraView={
          <>
            {!loading && !activeGuest?.hype && (
              <FireThin
                className={cx(classes.iconInterested)}
                style={{
                  width: 16,
                  height: 16,
                }}
              />
            )}
            {!loading && activeGuest?.hype === true && (
              <SvgIcon
                className={cx(classes.iconInterested)}
                style={{
                  width: 16,
                  height: 16,
                }}
              >
                <path d={mdiFire} />
              </SvgIcon>
            )}
          </>
        }
        sx={{
          height: 32,
        }}
      >
        {t('interested')}
      </BashButton>
    )
  }

  // Default fire style button
  return (
    <div className={classes.root}>
      <svg style={{ width: 0, height: 0, position: 'absolute' }}>
        <defs>
          <radialGradient id='hypeGradient' cx='120%' cy='7%' r='203.5%'>
            <stop offset='0%' stopColor='#F33DF3' />
            <stop offset='33.85%' stopColor='#9B40F9' />
            <stop offset='82.29%' stopColor='#3D44F7' />
            <stop offset='100%' stopColor='#3F6AD4' />
          </radialGradient>
        </defs>
      </svg>
      <div
        className={cx(
          classes.hypeContainer,
          percentage > 0 && classes.hypeContainerFilling,
        )}
      >
        {isHot && <div className={classes.hotCircle} />}

        <Button
          className={cx(classes.statusButton, isHot && classes.statusButtonHot)}
          onMouseDown={hypeClicked}
          style={{
            width: size,
            height: size,
            background: isHot
              ? 'none'
              : theme.palette.mode === 'dark'
                ? 'black'
                : theme.palette.background.paper,
          }}
        >
          {loading && (
            <CircularProgress
              style={{ color: isHot ? 'white' : '#600BC1' }}
              size={size / 2}
            />
          )}
          {!loading && !activeGuest?.hype && !isHot && (
            <FireThin
              className={cx(classes.icon)}
              style={{
                width: size === 40 ? 23 : 27,
                height: size === 40 ? 23 : 27,
              }}
            />
          )}
          {!loading && activeGuest?.hype === true && !isHot && (
            <SvgIcon
              className={cx(classes.icon)}
              style={{
                width: size === 40 ? 27 : 30,
                height: size === 40 ? 27 : 30,
              }}
            >
              <path d={mdiFire} />
            </SvgIcon>
          )}
          {!loading && !activeGuest?.hype && isHot && (
            <FireThin
              className={cx(classes.icon, classes.isHotIcon)}
              style={{
                width: size === 40 ? 23 : 27,
                height: size === 40 ? 23 : 27,
              }}
            />
          )}
          {!loading && activeGuest?.hype === true && isHot && (
            <SvgIcon
              className={cx(classes.icon, classes.isHotIcon)}
              style={{
                width: size === 40 ? 27 : 30,
                height: size === 40 ? 27 : 30,
              }}
            >
              <path d={mdiFire} />
            </SvgIcon>
          )}
        </Button>

        {!isHot && (
          <CircularProgress
            color='secondary'
            className={classes.progress}
            size={size}
            thickness={2.67}
            value={percentage}
            variant={'determinate'}
          />
        )}
      </div>
    </div>
  )
}

export default HypeButton
