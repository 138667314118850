import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentEvent } from '../../../selectors/event'
import { Box, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import DropZone from 'components/editEvent/DropZone'
import {
  setEditingPost,
  setEventUpdatesModal,
  setImagesChatModal,
} from 'actions/modals'
import FullScreenSheet from '../../modals/FullScreenSheet'
import apiClient from '../../../shared-components/utils/ApiClient'
import YesNoDialog from '../../dialog/YesNoDialog'
import PostEventUpdates from '../posts/PostEventUpdates'
import { defaultEditingPost } from '../../../reducers/modals'
import {
  EditorComponent,
  Remirror,
  useChainedCommands,
  useHelpers,
  useRemirror,
  useRemirrorContext,
} from '@remirror/react'
import DescriptionMenu from '../../EventCreation/DescriptionMenu'
import {
  BoldExtension,
  BulletListExtension,
  HardBreakExtension,
  HeadingExtension,
  ItalicExtension,
  LinkExtension,
  MarkdownExtension,
  OrderedListExtension,
  UnderlineExtension,
} from 'remirror/extensions'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ChooseImageModal from '../../modals/ChooseImageModal'
import { DndProvider } from 'react-dnd'
import { useMultimediaLogic } from '../../EventCreation/MultimediaLogic'
import MultimediaViewer from '../media/MultimediaViewer'
import { useUser } from '../../../utils/userFunctions'
import GifPickerModal from 'components/modals/GifPickerModal'
import BashButton, {
  BashButtonType,
} from '../../../shared-components/buttons/BashButton'
import { remirrorStyles } from '../posts/Comment'
import Column from '../../Column'
import Row from '../../Row'
import { EventDto } from '../../../types/event'
import { RsvpStatus } from '../../../types/types'
import PostPosterDropdown from './PostPosterDropdown'
import { PostDto, SendPostBody } from '../../../types/post'
import PostRecipientSelection from './PostRecipientSelection'

// Keep makeStyles only for Remirror-related styles
const useRemirrorStyles = makeStyles()((theme) => ({
  remirror: {
    height: '100%',
    outline: 'none',
    overflowY: 'auto',
    padding: theme.spacing(1.5),
    flexGrow: 1,
    ...remirrorStyles(theme),
  },
  editorMenu: {
    margin: theme.spacing(0, 0),
  },
}))

interface TextUpdaterProps {
  state: any
  open: boolean
}

// Sets the textMarkdown and text fields of the editingPost whenever an edit is made in the Remirror component
const TextUpdater: React.FC<TextUpdaterProps> = ({ state, open }) => {
  const { getMarkdown, getText } = useHelpers()
  const dispatch = useDispatch()
  const editingPost = useSelector((state: any) => state.modals.editingPost)

  useEffect(() => {
    if (open) {
      dispatch(
        setEditingPost({
          ...editingPost,
          textMarkdown: getMarkdown(state),
          text: getText().replaceAll('\u0000', '\n'),
        }),
      )
    }
  }, [state, open])

  return <div />
}

// Sets the content of the Remirror component whenever a new post is selected
const InitialMarkdownSetter = () => {
  const editingPost = useSelector((state: any) => state.modals.editingPost)
  const [hasSet, setHasSet] = useState(false)

  const { setContent } = useRemirrorContext()
  const chain = useChainedCommands()

  useEffect(() => {
    if (editingPost.id && !hasSet) {
      if (editingPost.textMarkdown && editingPost.textMarkdown.length > 0) {
        // Clear text and set content to markdown
        setContent({
          type: 'doc',
          content: [],
        })
        chain.insertMarkdown(editingPost.textMarkdown).run()
      } else {
        setContent({
          type: 'doc',
          content: [],
        })
        chain.insertMarkdown(editingPost.text).run()
      }

      setHasSet(true)
    } else if (!editingPost.id && !hasSet) {
      setContent({
        type: 'doc',
        content: [],
      })
      setHasSet(true)
    }
  }, [editingPost.id, hasSet])

  useEffect(() => {
    setHasSet(false)
  }, [editingPost.id])

  return <div />
}

interface CreatePostModalProps {
  open: boolean
  onClose: () => void
  onSendMessage: (message: any) => void
}

const CreatePostModal: React.FC<CreatePostModalProps> = ({
  open,
  onClose,
  onSendMessage,
}) => {
  const { user } = useUser()
  const { classes: remirrorClasses, cx } = useRemirrorStyles()
  const theme = useTheme()
  const { t } = useTranslation('common')
  const event: EventDto = useSelector(getCurrentEvent)
  const myGuest = event.myGuest
  const dispatch = useDispatch()
  const [gifOpen, setGifOpen] = useState(false)
  const [sendBody, setSendBody] = useState<SendPostBody>({
    going: true,
    maybe: true,
    interested: true,
    waitlist: true,
    cant: false,
    invitePending: false,
  })

  const onSend = (message: any) => {
    onSendMessage(message)
  }

  const fileInputRef = useRef<HTMLInputElement>(null)

  const editingPost: PostDto = useSelector(
    (state: any) => state.modals.editingPost,
  )
  const eventUpdates = editingPost.eventUpdates
  // const fromOrganisation = editingPost.fromOrganisation
  // const fromGuest = editingPost.fromGuest
  const imageInputRef = useRef<HTMLInputElement>(null)
  const [imageModalOpen, setImageModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const autoFocusImageInput = useSelector(
    (state: any) => state.modals.autoFocusImageInput,
  )
  const postAsHost = useSelector((state: any) => state.modals.postAsHost)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)

  const { uploadImage, onRemoveImageClicked, uploadMedia } = useMultimediaLogic(
    {
      media: editingPost.media,
      setMedia: (newMedia: any) => {
        dispatch(
          setEditingPost({
            ...editingPost,
            media: newMedia,
          }),
        )
      },
      dispatch: dispatch,
      event: event,
    },
  )

  const onSetImageClicked = () => {
    setImageModalOpen(true)
  }

  const hasGuestThatCanPost = (
    [
      RsvpStatus.JOINED,
      RsvpStatus.GOING,
      RsvpStatus.MAYBE,
      RsvpStatus.CANT,
    ] as (RsvpStatus | undefined | null)[]
  ).includes(event.myGuest?.status)

  const linkExtension = new LinkExtension({
    autoLink: true,
    selectTextOnClick: true,
    defaultTarget: '_blank',
    defaultProtocol: 'https:',
  })
  const { manager, onChange, state } = useRemirror({
    extensions: () => [
      new BoldExtension(),
      new ItalicExtension(),
      new UnderlineExtension(),
      new HeadingExtension(),
      new HardBreakExtension(),
      new BulletListExtension(),
      new OrderedListExtension(),
      new MarkdownExtension(),
      linkExtension,
    ],
    content:
      editingPost.textMarkdown && editingPost.textMarkdown?.trim?.() !== ''
        ? editingPost.textMarkdown
        : editingPost.text,
    stringHandler:
      editingPost.textMarkdown != null &&
      editingPost.textMarkdown.trim?.() !== ''
        ? 'markdown'
        : 'text',
  })

  const clearEditor = () => {
    manager.view.updateState(manager.createState({ content: '' }))
  }

  const send = async () => {
    setLoading(true)
    try {
      const body: PostDto = {
        ...editingPost,
        parentEvent: event,
      }
      const res = editingPost.id
        ? await apiClient.event.patchPostV2(editingPost.id, body, myGuest?.code)
        : await apiClient.event.createPostV2(body, myGuest?.code, false)

      if (res.id) {
        await uploadMedia(null, res.id)
        if (!editingPost.id) {
          const sendResult = await apiClient.event.sendPostNotif(
            res.id,
            myGuest?.code,
            sendBody,
          )
        }
      }

      onSend({
        ...res,
        media: editingPost.media.map((m: any) => ({
          ...m,
          uploaded: true,
        })),
        content: {
          ...res.content,
          model: {
            ...res.content?.model,
            guest: {
              ...res.content?.model?.guest,
              user: user,
            },
          },
        },
      })

      dispatch(setEditingPost(defaultEditingPost))
      dispatch(setImagesChatModal([]))
      dispatch(setEventUpdatesModal([]))
      setLoading(false)
      clearEditor()
      onClose()
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  }

  const inputImageRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (autoFocusImageInput) {
      inputImageRef?.current?.focus()
    }
  }, [autoFocusImageInput])

  const onCloseModal = () => {
    if (editingPost.id) {
      dispatch(setEditingPost(defaultEditingPost))
      clearEditor()
      onClose()
    } else {
      if (editingPost.text && editingPost.text.length > 0) {
        setDeleteDialogOpen(true)
      } else if (eventUpdates && eventUpdates.length > 0) {
        setDeleteDialogOpen(true)
      } else if (editingPost.media && editingPost.media.length > 0) {
        setDeleteDialogOpen(true)
      } else {
        dispatch(setEditingPost(defaultEditingPost))
        clearEditor()
        onClose()
      }
    }
  }

  const onDeleteConfirm = () => {
    setDeleteDialogOpen(false)
    clearEditor()
    onClose()
  }

  const getSaveButtonText = () => {
    if (editingPost.id) return t('save')
    if (postAsHost) return t('sendUpdate')
    return t('comment')
  }

  const onGifSelected = async (url: string) => {
    setGifOpen(false)
    const file = await fetch(url)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], 'filename', {
            type: 'image/gif',
          }),
      )

    await uploadImage([file])
  }

  return (
    <FullScreenSheet
      open={open}
      onClose={onCloseModal}
      title={
        postAsHost
          ? t(editingPost.id ? 'edit' : 'sendAnUpdate')
          : t(editingPost.id ? 'edit' : 'comment')
      }
    >
      <DropZone
        sx={{
          // @ts-expect-error ignore types
          flexGrow: 1,
          height: 'unset',
          minHeight: '0px',
          display: 'flex',
          flexDirection: 'column',
        }}
        fileInputRef={fileInputRef}
        onNewFile={uploadImage}
        multiple
        title='Upload'
        fullScreen
      >
        <Column
          sx={{
            width: 1,
            maxWidth: 480,
            flexGrow: 1,
            minHeight: 0,
            bgcolor: 'background.paper',
            [theme.breakpoints.up('md')]: {
              maxHeight: 600,
            },
          }}
        >
          <Column
            sx={{
              overflowY: 'auto',
              flexGrow: 1,
              p: 2,
              pb: 0,
              gap: 2.5,
            }}
          >
            {postAsHost && (
              <Column
                sx={{
                  gap: 2.5,
                }}
              >
                <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                  {t('sendUpdateExplanation')}
                </Typography>

                <PostRecipientSelection
                  sendBody={sendBody}
                  setSendBody={setSendBody}
                />
              </Column>
            )}
            <Column
              sx={{
                justifyContent: 'space-between',
                bgcolor: 'background.input',
                borderRadius: 1.25,
                width: '100%',
                overflow: 'hidden',
                flexGrow: postAsHost ? 1 : 0,
                minHeight: 280,
                overflowY: 'hidden',
                '& .remirror-editor-wrapper': {
                  overflowY: 'auto',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                },
              }}
            >
              <Column
                sx={{
                  height: 1,
                  flexBasis: postAsHost ? 300 : 0,
                  flexShrink: 1,
                  flexGrow: 1,
                  overflowY: 'auto',
                  '& > :nth-of-type(2)': {
                    flexGrow: 1,
                  },
                }}
              >
                <Remirror
                  manager={manager}
                  classNames={[remirrorClasses.remirror]}
                  state={state}
                  onChange={onChange}
                  autoFocus={true}
                >
                  <DescriptionMenu
                    className={cx(remirrorClasses.editorMenu)}
                    withImages={true}
                    onAddImage={() => setImageModalOpen(true)}
                    onAddGif={() => setGifOpen(true)}
                  />
                  <EditorComponent />
                  <TextUpdater state={state} open={open} />
                  <InitialMarkdownSetter />
                </Remirror>
              </Column>
              <Box sx={{ m: 2 }}>
                <PostEventUpdates
                  post={{
                    eventUpdates: eventUpdates,
                  }}
                  onRemove={(id) => {
                    dispatch(
                      setEditingPost({
                        ...editingPost,
                        eventUpdates: editingPost.eventUpdates.filter(
                          (u) => u.id !== id,
                        ),
                      }),
                    )
                  }}
                />
              </Box>
            </Column>
          </Column>

          {editingPost.media.length > 0 && (
            <MultimediaViewer
              hideMainViewer
              mediaToUse={editingPost.media}
              imageInputRef={imageInputRef}
              onNewFile={uploadImage}
              onSetImageClicked={onSetImageClicked}
              onRemoveImageClicked={onRemoveImageClicked}
              sx={{
                // @ts-expect-error not typescript
                px: 2,
                height: 'unset',
                maxWidth: '100%',
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
              mediaSize={120}
            />
          )}

          <Row
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 2,
              minHeight: 68,
              width: 1,
            }}
          >
            <PostPosterDropdown open={open} />

            <BashButton
              type={BashButtonType.PRIMARY}
              onClick={send}
              enabled={
                editingPost.text.length > 0 || editingPost.media.length > 0
              }
              loading={loading}
              sx={{
                ml: 'auto',
              }}
            >
              {getSaveButtonText()}
            </BashButton>
          </Row>
        </Column>
      </DropZone>
      <DndProvider backend={HTML5Backend}>
        <ChooseImageModal
          open={imageModalOpen}
          onClose={() => setImageModalOpen(false)}
          inputRef={imageInputRef}
          onNewFile={uploadImage}
          mediaToUse={editingPost.media}
          setMedia={(newMedia) => {
            dispatch(
              setEditingPost({
                ...editingPost,
                media: newMedia,
              }),
            )
          }}
        />
      </DndProvider>
      <GifPickerModal
        onGifSelected={onGifSelected}
        open={gifOpen}
        onClose={() => setGifOpen(false)}
      />
      <YesNoDialog
        title={t('deletePost')}
        subtitle={t('deletePostSub')}
        confirmText={t('delete')}
        cancelText={t('cancel')}
        destructive
        open={deleteDialogOpen}
        onConfirm={onDeleteConfirm}
        onClose={() => setDeleteDialogOpen(false)}
        description={undefined}
        icon={undefined}
        loading={undefined}
      />
    </FullScreenSheet>
  )
}

export default CreatePostModal
