import Row from '../../Row'
import {
  Button,
  Checkbox,
  Menu,
  MenuItem,
  SxProps,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SendPostBody } from '../../../types/post'
import { isArray } from 'remirror'
import { ChevronRight } from '@mui/icons-material'
import React, { useEffect, useRef, useState } from 'react'
import { Theme } from '@mui/material/styles'
import AnimatedStateContent from '../../AnimatedStateContent'
import { useCurrentEvent } from '../../../utils/hooks'
import dayjs from 'dayjs'
import { EventDto } from '../../../types/event'
import apiClient from '../../../shared-components/utils/ApiClient'
import { editEvent } from '../../../actions/event'
import { useDispatch } from 'react-redux'

interface Props {
  sendBody: SendPostBody
  setSendBody: React.Dispatch<React.SetStateAction<SendPostBody>>
  sx?: SxProps<Theme>
}

const ALL_POST_OPTIONS = [
  'ALL_GUESTS',
  'GOING',
  'MAYBE',
  'INTERESTED',
  'WAITLIST',
  'CANT',
  'INVITE_PENDING',
] as const
type PostOption = (typeof ALL_POST_OPTIONS)[number]

const allGuestsChildren = ['GOING', 'MAYBE', 'WAITLIST', 'INTERESTED']

const PostRecipientSelection = ({ sendBody, setSendBody, sx = [] }: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const event: EventDto = useCurrentEvent()

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const ticketsAvailableLater = event.joinOptions?.find(
    (e) => e.availableFrom && dayjs(e.availableFrom).isAfter(dayjs()),
  )

  const loadStatusCounts = async () => {
    const statusCounts = await apiClient.event.getStatusCounts(event.id)
    if (statusCounts) {
      dispatch(
        editEvent({
          ...event,
          statusCountsHost: statusCounts,
        }),
      )
    }
  }

  const onDropdownClick = (e) => {
    // Create a copy of the currentTarget's bounding box to avoid position changes
    const targetCopy = e.currentTarget.getBoundingClientRect()

    // Create a new element to hold the position of the button statically
    const staticAnchor = document.createElement('div')
    staticAnchor.style.position = 'absolute'
    staticAnchor.style.top = `${targetCopy.top + window.scrollY}px`
    staticAnchor.style.left = `${targetCopy.left + window.scrollX}px`
    staticAnchor.style.width = `${targetCopy.width}px`
    staticAnchor.style.height = `${targetCopy.height}px`

    // Append the static element to the body
    document.body.appendChild(staticAnchor)

    setMenuAnchor(staticAnchor)
  }

  const getIsSelected = (option: PostOption) => {
    if (option === 'WAITLIST') return sendBody.waitlist
    if (option === 'INVITE_PENDING') return sendBody.invitePending
    if (option === 'MAYBE') return sendBody.maybe
    if (option === 'GOING') return sendBody.going
    if (option === 'INTERESTED') return sendBody.interested
    if (option === 'CANT') return sendBody.cant
    if (option === 'ALL_GUESTS') {
      return (
        sendBody.going &&
        sendBody.maybe &&
        sendBody.waitlist &&
        sendBody.interested
      )
    }
    return false
  }

  const onOptionClicked = (option: PostOption) => {
    const wasSelected = getIsSelected(option)

    setSendBody((prevSendBody) => {
      const updatedSendBody = { ...prevSendBody }

      switch (option) {
        case 'WAITLIST':
          updatedSendBody.waitlist = !wasSelected
          break

        case 'INVITE_PENDING':
          updatedSendBody.invitePending = !wasSelected
          break

        case 'MAYBE':
          updatedSendBody.maybe = !wasSelected
          break

        case 'CANT':
          updatedSendBody.cant = !wasSelected
          break

        case 'GOING':
          updatedSendBody.going = !wasSelected
          break

        case 'INTERESTED':
          updatedSendBody.interested = !wasSelected
          break

        case 'ALL_GUESTS':
          updatedSendBody.going = !wasSelected
          updatedSendBody.maybe = !wasSelected
          updatedSendBody.waitlist = !wasSelected
          updatedSendBody.interested = !wasSelected
          break

        default:
          break
      }

      return updatedSendBody
    })
  }

  const getOptionGuestCount = (option: PostOption) => {
    if (option === 'MAYBE') return event.statusCountsHost?.maybe
    if (option === 'CANT') return event.statusCountsHost?.cant
    if (option === 'GOING')
      return (
        (event.statusCountsHost?.going ?? 0) +
        (event.statusCountsHost?.joined ?? 0)
      )
    if (option === 'INTERESTED') return event.statusCountsHost?.hyped
    if (option === 'WAITLIST') return event.statusCountsHost?.waitingList
    if (option === 'INVITE_PENDING') return event.statusCountsHost?.invited
    if (option === 'ALL_GUESTS')
      return (
        (event.statusCountsHost?.going ?? 0) +
        (event.statusCountsHost?.joined ?? 0) +
        (event.statusCountsHost?.maybe ?? 0) +
        (event.statusCountsHost?.waitingList ?? 0) +
        (event.statusCountsHost?.hyped ?? 0)
      )
  }

  useEffect(() => {
    loadStatusCounts()
  }, [])

  return (
    <Row
      sx={{
        gap: 1.5,
        alignItems: 'flex-start',
      }}
    >
      <Typography
        variant='button'
        sx={{
          mt: 2,
        }}
      >
        {t('to')}:
      </Typography>

      <Button
        sx={[
          {
            flexGrow: 1,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: 1,
          },
          ...(isArray(sx) ? sx : [sx]),
        ]}
        ref={buttonRef}
        onClick={onDropdownClick}
      >
        <AnimatedStateContent
          getContent={(sendBody) => (
            <Row
              sx={{
                gap: 1,
                flexWrap: 'wrap',
              }}
            >
              {ALL_POST_OPTIONS.filter((o) => {
                if (
                  getIsSelected('ALL_GUESTS') &&
                  allGuestsChildren.includes(o)
                ) {
                  return false
                }
                return getIsSelected(o)
              }).map((oKey) => (
                <Typography
                  key={oKey}
                  variant='body2'
                  sx={{
                    p: 1.25,
                    whiteSpace: 'nowrap',
                    bgcolor: 'grey.150',
                    borderRadius: 1,
                  }}
                >
                  {t(
                    `postRecipient_${oKey === 'WAITLIST' && ticketsAvailableLater ? 'PREREGISTER' : oKey}`,
                  )}
                </Typography>
              ))}
            </Row>
          )}
          state={sendBody}
          animateHeight={true}
        />

        <ChevronRight
          sx={{
            transform: 'rotate(90deg)',
            width: 20,
            height: 20,
            mt: 1,
            ml: 'auto',
          }}
        />
      </Button>

      <Menu
        open={Boolean(menuAnchor)}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: {
            sx: {
              mt: 6,
              width: buttonRef.current?.clientWidth,
              maxHeight: 300,
            },
          },
        }}
      >
        {ALL_POST_OPTIONS.map((option) => {
          const translationKey =
            option === 'WAITLIST' && ticketsAvailableLater
              ? 'PREREGISTER'
              : option
          return (
            <MenuItem
              key={option}
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 1.5,
              }}
              onClick={() => onOptionClicked(option)}
            >
              <Row
                sx={{
                  alignItems: 'center',
                  gap: 1,
                  paddingLeft: allGuestsChildren.includes(option) ? 2 : 0,
                }}
              >
                <Checkbox
                  sx={{
                    p: 0,
                    '&.Mui-disabled': {
                      color: 'grey.800',
                    },
                  }}
                  disableRipple
                  size={'small'}
                  value={getIsSelected(option)}
                  checked={getIsSelected(option)}
                />
                <Typography variant='body2'>
                  {t(`postRecipient_${translationKey}`)}
                </Typography>

                <Typography
                  variant='body2'
                  sx={{
                    color: 'grey.600',
                  }}
                >
                  | {getOptionGuestCount(option)}
                </Typography>
              </Row>
            </MenuItem>
          )
        })}
      </Menu>
    </Row>
  )
}

export default PostRecipientSelection
