import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { SvgIcon, Typography } from '@mui/material'
import {
  LocalActivity,
  Lock,
  OpenInNewOutlined,
  TrendingUp,
  VerticalAlignTop,
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { mdiAccountCheck, mdiFire, mdiLock } from '@mdi/js'
import LockIcon from '@mui/icons-material/Lock'
import dayjs from 'dayjs'
import { formatShortNumber } from '../../utils/eventFunctions'

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: '4px 8px',
    background: theme.palette.mode === 'dark' ? '#333333' : '#F5F5F5',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    gap: theme.spacing(0.5),
    color: theme.palette.mode === 'dark' ? '#F5F5F5' : '#111111',
  },
  red: {
    background: theme.palette.mode === 'dark' ? '#5C1100' : '#FFF2F0',
    color: theme.palette.mode === 'dark' ? '#FFF2F0' : '#5C1100',
    border:
      '1px solid ' + (theme.palette.mode === 'dark' ? '#1F1F1F' : '#FAFAFA'),
  },
  hot: {
    background: theme.palette.mode === 'dark' ? '#FF8F45' : '#FF8F45',
    color: theme.palette.mode === 'dark' ? '#111111' : '#FFFFFF',
    padding: '4px 6px',
  },
  blue: {
    background: theme.palette.mode === 'dark' ? '#0B1B41' : '#F2F5FD',
    color: theme.palette.mode === 'dark' ? '#D3DEF8' : '#18398C',
  },
  pink: {
    background: theme.palette.mode === 'dark' ? '#360736' : '#FDF2FD',
    color: theme.palette.mode === 'dark' ? '#FDF2FD' : '#901490',
  },
  orange: {
    background: theme.palette.mode === 'dark' ? '#611D00' : '#FFEFE5',
    color: theme.palette.mode === 'dark' ? '#FFAC93' : '#FF7145',
  },
  secondary: {
    background: theme.palette.mode === 'dark' ? '#240448' : '#F7F1FE',
    color: theme.palette.mode === 'dark' ? '#E9D8FD' : '#240448',
  },
  text: {
    fontSize: '10px',
  },
  icon: {
    width: 14,
    height: 14,
  },
  iconSmall: {
    width: 12,
    height: 12,
  },
  iconExtraSmall: {
    width: 10,
    height: 10,
  },
}))

export const TicketBadge = ({ ticketOption }) => {
  const { classes, cx } = useStyles()

  const formatter = new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: ticketOption?.currency ?? 'EUR',
  })

  return (
    <div className={cx(classes.root)}>
      <LocalActivity className={classes.icon} />
      <Typography className={classes.text} variant='caption'>
        {formatter.format(ticketOption.price / 100)}
      </Typography>
    </div>
  )
}

export const PrivateBadge = () => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cx(classes.root, classes.secondary)}>
      <Lock className={classes.icon} />
      <Typography className={classes.text} variant='caption'>
        {t('private')}
      </Typography>
    </div>
  )
}

export const CancelledBadge = () => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cx(classes.root, classes.red)}>
      <Typography className={classes.text} variant='caption'>
        {t('cancelledEvent')}
      </Typography>
    </div>
  )
}

export const HypeBadge = ({ event }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cx(classes.root, classes.hot)}>
      <SvgIcon className={classes.iconSmall} color='inherit'>
        <path d={mdiFire} />
      </SvgIcon>
      <Typography
        className={classes.text}
        variant='caption'
        style={{
          fontWeight: 500,
        }}
      >
        {formatShortNumber(event.hype.hypeScore)}
      </Typography>
    </div>
  )
}

export const ApprovalBadge = () => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cx(classes.root, classes.red)}>
      <SvgIcon className={classes.iconSmall} color='inherit'>
        <path d={mdiAccountCheck} />
      </SvgIcon>
      <Typography className={classes.text} variant='caption'>
        {t('approvalRequired')}
      </Typography>
    </div>
  )
}

export const HiddenOptionBadge = () => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cx(classes.root, classes.secondary)}>
      <LockIcon className={classes.iconSmall} color='inherit' />
      <Typography className={classes.text} variant='caption'>
        {t('hiddenOption')}
      </Typography>
    </div>
  )
}

export const AvailableUntilBadge = ({ joinOption }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cx(classes.root, classes.secondary)}>
      <Typography className={classes.text} variant='caption'>
        {t('availableUntil')}{' '}
        {dayjs(joinOption.availableTo).format('D MMM HH:mm')}
      </Typography>
    </div>
  )
}

export const AvailableFromBadge = ({ joinOption }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cx(classes.root, classes.secondary)}>
      <Typography className={classes.text} variant='caption'>
        {t('availableFromDate', {
          date: dayjs(joinOption.availableFrom).format('D MMM HH:mm'),
        })}
      </Typography>
    </div>
  )
}

export const HiddenBadge = ({ joinOption }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cx(classes.root, classes.secondary)}>
      <SvgIcon className={classes.iconSmall} color='inherit'>
        <path d={mdiLock} />
      </SvgIcon>
      <Typography className={classes.text} variant='caption'>
        {t('hidden')}
      </Typography>
    </div>
  )
}

export const ExternalBadge = () => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cx(classes.root, classes.blue)}>
      <Typography className={classes.text} variant='caption'>
        {t('external')}
      </Typography>
      <OpenInNewOutlined className={classes.iconExtraSmall} />
    </div>
  )
}

export const ExternalRegistrationBadge = () => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cx(classes.root)}>
      <Typography className={classes.text} variant='caption'>
        {t('infoOnly')}
      </Typography>
    </div>
  )
}

export const NoRegistrationBadge = () => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cx(classes.root)}>
      <Typography className={classes.text} variant='caption'>
        {t('externalPage')}
      </Typography>
    </div>
  )
}

export const NearCapacityBadge = () => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cx(classes.root, classes.pink)}>
      <VerticalAlignTop className={classes.iconExtraSmall} />
      <Typography className={classes.text} variant='caption'>
        {t('nearCapacity')}
      </Typography>
    </div>
  )
}

export const TrendingPageBadge = () => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={cx(classes.root, classes.orange)}>
      <TrendingUp className={classes.iconExtraSmall} />
      <Typography className={classes.text} variant='caption'>
        {t('trendingPage')}
      </Typography>
    </div>
  )
}
